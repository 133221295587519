import React from 'react';
import logo from './logo.svg';
import './App.css'

import { Theme, Button } from '@averagedev/ui'

function App() {
  const handeClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => console.log(event)
  
  return (
    <Theme>
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.tsx</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
            >
            Learn React
          </a>
          <Button text="Test" onClick={handeClick} />
        </header>
      </div>
    </Theme>
  );
}

export default App;
